const projects = [
  {
    name: "Icescape Tropicana",
    about:
      "Icescape Tropicana is the UK's largest undercover ice rink. After being closed over Covid-19 Icescape Tropicana wanted a new site to accompliment their re-brand. It's built using the latest React features including Server Components.",
    details: [
      "React",
      "Next.js (App Router)",
      "Framer Motion",
      "HyGraph CMS",
      "GraphQL",
      "Typescript",
      "Tailwind",
    ],
    phoneImage: "icescape-phone.png",
    laptopImage: "icescape-laptop.png",
    website: "https://icescape-tropicana.co.uk",
  },
  {
    name: "Windmill Hill Car Sales",
    about:
      "Windmill Hill Car Sales are a new Bristol based used car sales & MOT garage. This build required integration with the Autotrader API using their webhook service to push updates from Autotrader into this frontend.",
    details: [
      "React",
      "Next.js (App Router)",
      "Framer Motion",
      "HyGraph CMS",
      "PlanetScale",
      "Resend",
      "Inngest",
      "GraphQL",
      "Typescript",
      "Tailwind",
    ],

    phoneImage: "windmill-hill-phone.png",
    laptopImage: "windmill-hill-laptop.png",
    website: "https://windmillhillcarsales.co.uk",
  },
  {
    name: "Ivy & Pine Photography",
    about:
      "Ivy & Pine Photography are a photography team from Bristol that needed a portfolio site to showcase their latest shoots. This was built with Next.js and HyGraph CMS for the content",
    details: [
      "React",
      "Next.js",
      "Framer Motion",
      "HyGraph CMS",
      "GraphQL",
      "Typescript",
    ],

    phoneImage: "ivyandpine-phone.png",
    laptopImage: "ivyandpine-laptop.png",
    website: "https://www.ivyandpinephotography.com",
  },
  {
    name: "Ansae",
    about:
      "Ansae is an online shop showcasing craftspeople, makers and artists from around the globe. They wanted a clean UI which put an emphasis on the product and artist.",
    details: ["Shopify", "Liquid", "HTML/CSS", "Shopify CLI"],

    phoneImage: "ansae-phone.png",
    laptopImage: "ansae-laptop.png",
    website: "https://www.ansae.co.uk",
  },
  {
    name: "Weatherly",
    about:
      "Weatherly is a simple weather app for finding the weather conditions of your current position using the OpenWeatherMap API. It also displays the weather for the following 24 hours and 7 days.",
    details: ["JavaScript", "CSS", "OpenWeatherMap API", "Anime JS", "Parcel"],
    phoneImage: "weatherly-phone.png",
    laptopImage: "weatherly-laptop.png",
    github: "https://github.com/mattdjenkinson/weatherly-app",
    website: "https://weatherly-test.netlify.app",
  },
  {
    name: "Ashely Holbrook",
    about:
      "Ashley is a producer and composer who needed a simple one page site to showcase previous and upcomming work. For this build i used jQuery to speed up development.",
    details: ["JavaScript", "jQuery", "CSS", "Anime JS"],
    phoneImage: "ah-phone.png",
    laptopImage: "ah-laptop.png",
    github: "https://github.com/mattdjenkinson/Ash-Website-2021",
    website: "https://ashleyholbrook.netlify.app",
  },
];

export { projects };
