<template>
  <div class="splash" v-if="!isLoaded">
    <div class="loader"></div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
export default {
  setup(_, { emit }) {
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        isLoaded.value = true;
        emit("isLoaded");
      }
    };

    const isLoaded = ref(false);

    return { isLoaded };
  },
};
</script>

<style scoped>
.splash {
  height: 100vh;
  width: 100vw;
  position: fixed;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--maintext-clr);

  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);

  z-index: 10000;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
}
.loader {
  color: var(--background-clr);

  margin: 55px auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before,
.loader:after {
  position: absolute;
  content: "";
}
.loader:before {
  width: 5.2em;
  height: 10.2em;
  background: var(--maintext-clr);
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  -webkit-transform-origin: 5.1em 5.1em;
  transform-origin: 5.1em 5.1em;
  -webkit-animation: load2 2s infinite ease 1.5s;
  animation: load2 2s infinite ease 1.5s;
}
.loader:after {
  width: 5.2em;
  height: 10.2em;
  background: var(--maintext-clr);
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 4.9em;
  -webkit-transform-origin: 0.1em 5.1em;
  transform-origin: 0.1em 5.1em;
  -webkit-animation: load2 2s infinite ease;
  animation: load2 2s infinite ease;
}
@-webkit-keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
