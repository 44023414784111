import { createApp } from "vue";
import App from "./App.vue";
import smoothscroll from "smoothscroll-polyfill";

// Smooth Scroll
smoothscroll.polyfill();

// Viewport
let vh = window.innerHeight * 0.01;

document.documentElement.style.setProperty("--vh", `${vh}px`);

createApp(App).mount("#app");
