<template>
  <nav>
    <div class="logo" @click="AboutScrollDown">
      <h1>Matthew Jenkinson</h1>
    </div>
    <ul class="list-items">
      <li>
        <a @click="AboutScrollDown">About</a>
      </li>
      <li><a @click="WorkScrollDown">Work</a></li>
      <li><a @click="ContactScrollDown">Contact</a></li>

      <a
        class="git-link"
        href="https://github.com/mattdjenkinson"
        target="blank"
        ><i class="fab fa-github"></i
      ></a>
    </ul>
  </nav>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { onMounted } from "@vue/runtime-core";
export default {
  setup() {
    gsap.registerPlugin(ScrollTrigger, CSSRulePlugin);

    onMounted(() => {
      // Logo Animation
      gsap.to(".logo h1", {
        scrollTrigger: {
          trigger: ".website-container",
          scrub: true,
          end: "+=500",
        },
        autoAlpha: 1,
        y: 0,
      });
      // Nav Scroll Animation
      gsap.to(CSSRulePlugin.getRule("nav:before"), {
        scrollTrigger: {
          trigger: "nav:before",
          scrub: 1,
        },
        width: "100%",
        transformOrigin: "left center",
      });
    });

    const AboutScrollDown = () => {
      document.querySelector("header").scrollIntoView({ behavior: "smooth" });
    };
    const WorkScrollDown = () => {
      document.querySelector("#work").scrollIntoView({ behavior: "smooth" });
    };
    const ContactScrollDown = () => {
      document.querySelector("footer").scrollIntoView({ behavior: "smooth" });
    };

    return { ContactScrollDown, AboutScrollDown, WorkScrollDown };
  },
};
</script>

<style>
nav {
  height: 5em;
  width: 100%;
  background-color: var(--nav-clr);

  display: flex;
  align-items: center;
  justify-content: space-around;

  position: fixed;
  top: 0;

  backdrop-filter: blur(11px);

  z-index: 100;
}

nav:before {
  content: "";
  position: absolute;
  width: 0%;
  height: 2px;
  background-color: var(--highlight-second-clr);

  bottom: 0;
  left: 0;
}

nav .logo h1 {
  color: var(--background-clr);
  font-size: 2rem;
  opacity: 0;
  transform: translateY(-100px);
  cursor: pointer;
  margin-top: 5px;
}

nav .list-items {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 5px;
}

nav .list-items li {
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
  margin-left: 30px;
}

nav a {
  color: var(--background-clr);
  transition: color 300ms ease-in-out;
  cursor: pointer;
  display: flex;
  align-items: center;
}

nav a:hover,
nav a:focus {
  /* color: var(--highlight-second-clr); */
  filter: invert(70%);
}

nav li::after {
  content: "";
  display: block;
  width: 0;
  height: 1px;
  border-radius: 5px;
  background: var(--highlight-second-clr);
  transition: width 0.3s;
}

nav li:hover::after {
  width: 100%;
}

.git-link {
  margin-left: 30px;
  transition: all 300ms ease-in-out;
  display: flex;
  align-items: center;
  margin-top: -2px;
}

.git-link:hover,
.git-link:focus {
  transform: rotate(180deg);
}

/* Smaller Tablet */

@media only screen and (max-width: 700px) {
  nav {
    justify-content: space-around;
  }
  nav .logo h1 {
    font-size: 1.25rem;
  }

  nav .list-items {
    font-size: 0.75rem;
  }
  nav .list-items li {
    margin-left: 15px;
  }

  .git-link {
    margin-left: 15px;
  }

  .dark-toggle {
    margin-left: 15px;
  }
}

/* iPhone X */

@media only screen and (max-width: 400px) {
  .git-link {
    display: none;
  }

  nav {
    height: 3rem;
  }

  nav .logo h1 {
    font-size: 1.15rem;
  }
}

/* iPhone SE */

@media only screen and (max-width: 320px) {
  nav {
    height: 2rem;
  }

  nav .logo h1 {
    font-size: 0.75rem;
  }

  nav .list-items {
    font-size: 0.5rem;
  }
}
</style>
