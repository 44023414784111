<template>
  <transition name="component-fade" mode="out-in">
    <Splash @isLoaded="loaded" />
  </transition>
  <Nav />

  <header>
    <div class="header-text">
      <p id="header-anim">Hey, my name is</p>
      <h2 id="header-anim">Matthew Jenkinson</h2>
      <p id="header-anim" class="header-about">
        I'm a full stack developer located in Bristol, UK. After working in the
        music industry for the past 10 years I thought I'd give this internet
        thing a go, and turns out I have quite the passion for building clean
        and exciting web experiences!
      </p>

      <a href="mailto:contact@matthewjenkinson.dev"
        ><button id="button-anim">Get In Touch</button></a
      >
    </div>

    <div class="header-image">
      <img src="@/assets/matt-picture.jpg" alt="Matt Jenkinson" />
    </div>
    <div class="scroll-down">
      <p class="bounce">Scroll Down</p>
      <i class="fal fa-long-arrow-down"></i>
    </div>
    <div class="dark-mode">
      <i class="fad fa-adjust" @click="toggleDark"></i>
    </div>
  </header>
  <section id="work">
    <h2>Recent Work</h2>
    <div class="work-container">
      <div class="website-container" v-for="(project, i) in data" :key="i">
        <div class="website-about">
          <h3>{{ project.name }}</h3>
          <p>{{ project.about }}</p>
          <ul>
            <li v-for="detail in project.details" :key="detail">
              <i class="fas fa-caret-right"></i>{{ detail }}
            </li>
          </ul>
          <div class="links">
            <a v-if="project.github" :href="project.github" target="blank"
              ><i class="fab fa-github"></i
            ></a>
            <a v-if="project.website" :href="project.website" target="blank"
              ><i class="fas fa-globe"></i
            ></a>
          </div>
        </div>
        <div class="website-images">
          <img
            v-if="project.phoneImage"
            :src="require(`@/assets/work-photos/${project.phoneImage}`)"
            alt="Website
          Photo"
            class="phone"
          />
          <img
            v-if="project.laptopImage"
            :src="require(`@/assets/work-photos/${project.laptopImage}`)"
            alt="Website Photo"
            class="laptop"
          />
        </div>
      </div>
    </div>
  </section>
  <Footer />
</template>

<script>
import Nav from "./components/Nav.vue";
import Footer from "./components/Footer.vue";
import Splash from "./components/Splash.vue";
import { gsap } from "gsap";
import { onMounted, ref, watchEffect } from "@vue/runtime-core";
import { projects } from "./data.js";
import { useDark, useToggle } from "@vueuse/core";

export default {
  components: { Nav, Footer, Splash },

  setup() {
    onMounted(() => {
      watchEffect(() => {
        if (isLoaded.value == true) {
          // Header Animations
          gsap.from(".header-image", {
            x: 300,
          });
          gsap.from("#header-anim", {
            x: -500,
            stagger: 0.3,

            opacity: 0,
          });
          gsap.to("#button-anim", {
            duration: 0.5,
            delay: 0.7,
            opacity: 1,
            ease: "sine.inOut",
          });
          gsap.from(".list-items li", {
            y: -100,
            stagger: 0.3,
          });
          gsap.from(".dark-mode", {
            x: -100,
            delay: 1.2,
          });

          gsap.from(".scroll-down", {
            x: 100,
            delay: 1.2,
          });
          gsap.to(".scroll-down", {
            scrollTrigger: {
              trigger: "#work",
              scrub: true,
              end: "+=800",
            },
            // bottom: "100%",
            y: -500,
          });
          // Nav Animation
          gsap.to("nav", {
            scrollTrigger: {
              trigger: "#work",
              start: "-10%",
              end: "20%",
              scrub: true,
            },
            height: "3em",
            boxShadow: "5px 14px 18px -6px rgba(0, 0, 0, 0.10)",
          });
        }
      });

      // Website Sections Animations
      gsap.utils.toArray(".website-container").forEach((section) => {
        gsap.from(section.querySelectorAll("h3, p, ul"), {
          scrollTrigger: {
            trigger: section,
          },
          autoAlpha: 0,
          y: 25,
          stagger: 0.2,
        });
      });
      gsap.utils.toArray(".website-container").forEach((section) => {
        gsap.from(section.querySelectorAll(".links, li"), {
          scrollTrigger: {
            trigger: section,
          },
          autoAlpha: 0,
          y: 25,
          stagger: 0.3,
        });
      });
      gsap.utils.toArray(".website-container").forEach((section) => {
        gsap.from(section.querySelectorAll("img"), {
          scrollTrigger: {
            trigger: section,
          },

          autoAlpha: 0,
          stagger: 0.5,
          delay: 0.5,
        });
      });
    });

    const isDark = useDark({
      selector: ":root",
      attribute: "color-scheme",
      valueDark: "dark",
      valueLight: "light",
    });
    const toggleDark = useToggle(isDark);

    const isLoaded = ref(false);

    const loaded = () => {
      isLoaded.value = !isLoaded.value;
    };

    const data = projects;

    const ContactScrollDown = () => {
      document.querySelector("footer").scrollIntoView({ behavior: "smooth" });
    };

    return { data, ContactScrollDown, loaded, toggleDark, isDark };
  },
};
</script>

<style>
/* @import url("https://fonts.googleapis.com/css2?family=Caveat&family=Poppins:wght@300;500&display=swap"); */

@font-face {
  font-family: "Woodland";
  src: url("~@/assets/fonts/PPWoodland-Bold.woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Woodland Reg";
  src: url("~@/assets/fonts/PPWoodland-Regular.woff");
}

@font-face {
  font-family: "Neue";
  src: url("~@/assets/fonts/NeueMontreal-Light.woff");
  font-weight: normal;
  font-style: normal;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root[color-scheme="light"] {
  --background-clr: #222831;
  --maintext-clr: #e9e9e9;
  --nav-clr: #e9e9e9de;
  --highlight-main-clr: #dddddd;
  --highlight-second-clr: #aaabae;
  --icon-clr: #222831;
  transition: all 500ms;
}

:root[color-scheme="dark"] {
  --background-clr: #dddddd;
  --maintext-clr: #222831;
  --nav-clr: #222831d7;
  --highlight-main-clr: #252e3a;
  --highlight-second-clr: #aaabae;
  --icon-clr: #dddddd;

  transition: all 500ms;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background-clr: #dddddd;
    --maintext-clr: #222831;
    --nav-clr: #222831d7;
    --highlight-main-clr: #252e3a;
    --highlight-second-clr: #aaabae;
    --icon-clr: #dddddd;

    transition: all 500ms;
  }
}

html {
  -webkit-tap-highlight-color: transparent;
  overflow-x: hidden;
}

body {
  background-color: var(--highlight-main-clr);
  font-family: "Neue", sans-serif;
  font-weight: 300;
}

section {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  color: var(--background-clr);

  padding: 10%;
}

section h2 {
  font-size: 3rem;
}

h1 {
  font-family: "Woodland";
  padding: 0;
  margin: 0;
}

h2,
h3 {
  font-family: "Woodland Reg";
  padding: 0;
  margin: 0;
}

p {
  font-family: "Neue", sans-serif;
  font-weight: 300;
  letter-spacing: 0.05em;
}

a {
  font-family: "Neue", sans-serif;
  font-weight: 500;
  text-decoration: none;
  letter-spacing: 0.05em;
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}

.component-fade-enter-from,
.component-fade-leave-to {
  opacity: 0;
}

header {
  /* height: 100vh; */
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: var(--maintext-clr);

  padding: 0 10%;
  position: relative;
}

.header-text {
  color: var(--background-clr);
  width: 70%;
}

.header-text h2 {
  font-size: 6rem;
  /* margin-bottom: 20px; */
}

.header-text p {
  font-size: 1.5rem;
  margin: 30px 0;
}

.header-about {
  padding-right: 100px;
}

.header-text button {
  margin-top: 20px;
}

button {
  font-family: "Woodland Reg", sans-serif;
  cursor: pointer;
  font-size: 1rem;

  padding: 15px 30px;
  border-radius: 5px;
  border: none;
  background-color: var(--background-clr);
  color: var(--maintext-clr);
  transition: all 300ms ease-in-out;
}

#button-anim {
  opacity: 0;
}

button:hover,
button:focus {
  background-color: var(--highlight-main-clr);
  color: var(--background-clr);

  -webkit-box-shadow: 5px 14px 18px -6px rgba(0, 0, 0, 0.32);
  box-shadow: 5px 14px 18px -6px rgba(0, 0, 0, 0.32);
}

.header-image {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

header img {
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;

  object-fit: cover;
  object-position: bottom;

  -webkit-box-shadow: 5px 14px 18px -6px rgba(0, 0, 0, 0.32);
  box-shadow: 5px 14px 18px -6px rgba(0, 0, 0, 0.32);
}

.scroll-down {
  position: absolute;
  color: var(--background-clr);

  bottom: 2%;
  right: 2%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.scroll-down p {
  writing-mode: vertical-rl;
  margin-bottom: 20px;
}

.scroll-down i {
  font-size: 2rem;
}

.bounce {
  animation: 3s bounce 1.2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.dark-mode {
  position: fixed;
  color: var(--background-clr);

  bottom: 2%;
  left: 2%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  z-index: 900;
}

.dark-mode p {
  writing-mode: vertical-rl;
  margin-bottom: 20px;
  transform: rotate(180deg);
}

.dark-mode i {
  font-size: 2rem;
  cursor: pointer;
}

.work-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.website-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 150px 0;
}

.website-container:nth-child(even) {
  flex-direction: row-reverse;
}

.website-about {
  display: flex;
  flex-direction: column;

  width: 50%;

  padding: 0 50px;
}

.website-container:nth-child(even) .website-about {
  padding-left: 100px;
  padding-right: 0;
}

.website-about h3 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.website-about p {
  margin-bottom: 20px;
}

.website-about ul {
  margin: 0;
  padding: 0;
  list-style-type: none;

  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
}

.website-about li {
  flex: 1 0 50%;
  padding-top: 0.5em;
}

.website-about li i {
  padding-right: 10px;
  color: var(--highlight-second-clr);
}

.website-images {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 50%;
  padding: 0 50px;
  position: relative;
}

.website-container:nth-child(even) .website-images {
  justify-content: flex-start;
}

.website-images .phone {
  position: absolute;
  margin-top: 1.5em;
  z-index: 1;
  width: 150px;
  transition: transform 500ms;
}

.website-images .laptop {
  position: absolute;
  width: 600px;
  transition: transform 500ms;
}

@media only screen and (min-width: 600px) {
  .website-images:hover .phone {
    transform: translateX(30px) scale(1.01);
  }

  .website-images:hover .laptop {
    transform: translateX(-30px) scale(1.01);
  }

  .website-container:nth-child(even) .website-images:hover .laptop {
    transform: translateX(30px) scale(1.01);
  }

  .website-container:nth-child(even) .website-images:hover .phone {
    transform: translateX(-30px) scale(1.01);
  }
}

.links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 10%;
  margin-top: 20px;
  font-size: 1.5rem;

  z-index: 50;
}

.links a {
  color: var(--icon-clr);
  margin-right: 20px;
  transition: all 300ms ease-in-out;
}

.links a:hover,
.links a:focus {
  /* margin-right: 20px; */

  filter: drop-shadow(6px 8px 20px rgba(0, 0, 0, 0.13));

  transform: scale(1.1);
}

/* Smaller Laptop */

@media only screen and (max-width: 1280px) {
  section {
    padding: 5%;
  }
}

/* iPad Pro */

@media only screen and (max-width: 1024px) {
  header {
    flex-direction: column;
    text-align: center;
    justify-content: space-evenly;

    padding: 0 5%;
  }

  .header-text h2 {
    font-size: 4rem;
  }

  .header-text p {
    font-size: 1rem;
    margin: 30px 10%;
  }

  .header-about {
    padding-right: 0px;
  }

  .header-image {
    width: 100%;
  }
  .header-image img {
    position: relative;
    width: 250px;
    height: 250px;
  }

  section {
    padding: 10% 5%;
  }

  .website-container {
    flex-direction: column;
    justify-content: center;
    margin-bottom: -10%;
  }
  .website-container:nth-child(even) {
    flex-direction: column;
  }
  .website-about {
    width: 100%;
    align-items: center;
    text-align: center;
    padding: 0 15%;
  }

  .website-container:nth-child(even) .website-about {
    padding-left: 0px;
    padding-right: 0px;
    padding: 0 15%;
  }

  .website-images {
    width: 50%;
    justify-content: center;
    position: relative;
  }

  .website-container:nth-child(even) .website-images {
    justify-content: center;
  }

  .website-images .phone {
    position: absolute;
    width: 125px;
    margin-left: -60px;
    left: 0;
  }

  .website-images .laptop {
    position: unset;
    width: 500px;
  }
}

/* iPhone */

@media only screen and (max-width: 700px) {
  header {
    padding: 0 5%;
    justify-content: center;

    padding-top: 20%;
  }

  .header-text {
    width: 100%;
  }

  .header-text p {
    font-size: 1rem;
    margin: 10px 0;
  }

  .header-image {
    margin-top: 30px;
  }

  .header-image img {
    width: 200px;
    height: 200px;
  }

  section {
    padding: 20% 0%;
  }

  .website-container {
    margin: 0px 0;
    margin-top: 50px;
  }

  .website-about ul {
    display: block;
  }

  .website-about {
    display: flex;
    flex-direction: column;

    width: 100%;

    padding: 0 30px;
  }

  .website-container:nth-child(even) .website-about {
    padding: 0 30px;
  }

  .links {
    width: 100%;
    justify-content: center;
  }

  .links a {
    margin: 10px 10px 0px 10px;
  }

  .website-images .phone {
    position: absolute;
    width: 75px;
    margin-left: -50px;
  }

  .website-images .laptop {
    position: unset;
    width: 300px;
  }
}

/* iPhone 6/7/8 & Smaller */

@media only screen and (max-width: 400px) {
  .header-text p {
    font-size: 0.75rem;
  }

  header {
    padding: 0 10%;

    padding-top: 5em;

    justify-content: space-around;
  }

  .header-text h2 {
    font-size: 2.5rem;
  }

  .website-about {
    padding: 0 10px;
  }

  .scroll-down p {
    font-size: 0.75rem;
  }

  .scroll-down i {
    font-size: 1.5rem;
  }

  .dark-mode p {
    font-size: 0.75rem;
  }

  .dark-mode i {
    font-size: 1.5rem;
  }

  button {
    padding: 10px 20px;
  }

  section h2 {
    font-size: 2rem;
  }

  .website-about h3 {
    font-size: 1.25rem;
  }

  .header-image img {
    width: 150px;
    height: 150px;
  }
}
</style>
