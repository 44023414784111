<template>
  <footer>
    <h2>Contact</h2>
    <p>Have a project you want to work on?</p>

    <a href="mailto:contact@matthewjenkinson.dev"
      ><button>Let's Chat!</button></a
    >

    <div class="links">
      <a href="https://github.com/mattdjenkinson" target="blank"
        ><i class="fab fa-github"></i
      ></a>
      <a href="https://www.linkedin.com/in/matthew-d-jenkinson" target="blank"
        ><i class="fab fa-linkedin-in"></i
      ></a>
    </div>
  </footer>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { onMounted } from "@vue/runtime-core";
export default {
  setup() {
    gsap.registerPlugin(ScrollTrigger);

    onMounted(() => {
      gsap.from("footer button", {
        scrollTrigger: {
          trigger: "footer",
          scrub: true,

          start: "-100%",
          end: "-100%",
        },

        autoAlpha: 0,
        y: 50,
      });
    });

    return {};
  },
};
</script>

<style scoped>
footer {
  height: 100%;
  position: relative;
  background-color: var(--maintext-clr);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding: 10%;

  color: var(--background-clr);
}

button {
  margin-top: 50px;
}

.links {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  font-size: 1.5rem;

  z-index: 50;

  cursor: pointer;
}

.links a {
  color: var(--icon-clr);
  transition: all 300ms ease-in-out;
  /* margin-right: 0; */
  padding: 0 20px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.links i {
  transition: all 300ms ease-in-out;
}

.links i:hover,
.links i:focus {
  transform: scale(1.1);
}

h2 {
  font-size: 3rem;
  margin-bottom: 50px;
}

p {
  text-align: center;
}

/* Smaller Tablet */

@media only screen and (max-width: 700px) {
  footer {
    padding: 20% 5%;
  }
}

/* iPhone 6/7/8 & Smaller */

@media only screen and (max-width: 400px) {
  footer h2 {
    font-size: 2rem;
  }
}
</style>
